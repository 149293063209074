import { isString } from 'lodash';
import authStore from 'stores/authStore';

export async function rawApiRequest({ endpoint, parameters, body, token, method = 'GET', headers = {} }) {
	const queryParams = objectToQueryParams(parameters);

	const useToken = token || authStore?.values?.token; // pull token from auth store directly instead of requiring it to be passed in, but prioritize the passed in value if it's present
	if (useToken) {
		Object.assign(headers, {
			'Authorization': `Bearer ${useToken}`,
		});
	}

	let bodyToUse = body;
	if (isString(body) || body instanceof FormData || body instanceof Blob || body instanceof ArrayBuffer) {
		bodyToUse = body;
	} else {
		bodyToUse = JSON.stringify(body);
		headers['Content-Type'] = 'application/json';
	}

	const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/${endpoint}?${queryParams}`, {
		method,
		headers,
		body: bodyToUse,
	});

	const raw = await response.text();
	let data;

	try {
		data = JSON.parse(raw);
	} catch (err) {
		console.log('error parsing json', err);
	}

	return {
		status: response.status,
		data,
		raw,
		ok: response.ok,
	};
}

export default async function apiRequest({ endpoint, parameters, body, token, method = 'GET', headers }) {
	const response = await rawApiRequest({ endpoint, parameters, body, token, method, headers });

	return response.data;
}

function objectToQueryParams(incomingObject) {
	if (!incomingObject) {
		return '';
	}
	return Object.keys(incomingObject).filter(key => incomingObject[key] !== undefined && incomingObject[key] !== null).map(key => {
		return `${encodeURIComponent(key)}=${encodeURIComponent(incomingObject[key])}`;
	}).join('&');
}
